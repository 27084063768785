import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, Button } from '@mui/material';
import useQuoteList, { Props, ReceivedProps } from './hook';
import { QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import RedTrash from 'assets/icons/Trash.svg';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import EyeIcon from 'assets/icons/Eye.svg';
import InvoiceIcon from 'assets/icons/invoice-icon.svg';
import SearchGlass from 'assets/icons/search-glass.svg';
import plus from 'assets/icons/Plus.png';
import QuoteController from '../controller';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { useModal } from 'react-hooks-use-modal';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import { CloseOutlined } from '@mui/icons-material';
import defaultAvatar from 'assets/avatar-default.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import moment from 'moment';

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));

statusOptions.unshift({
    value: 'all',
    name: 'すべて'
})

const LeftOutlinedCustom = styled(LeftOutlined)``;

const typeOptions: SelectSearchOption[] = [
    { value: 'all', name: 'すべて' },
    { value: 'tax_consultation', name: '税務相談' },
    { value: 'consulting_service', name: 'コンサルティング業務依頼' },
    { value: 'insurance_estimate', name: '保険見積' },
    { value: 'other', name: 'その他' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '12px 14px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const QuoteListLayout: FC<Props> = ({
    quoteList,
    page,
    statusPrev,
    statusNext,
    setPagePrev,
    setPageNext,
    totalPage,
    setQuoteId,
    quoteId,
    deleteQuote,
    ModalDelete,
    openDelete,
    closeDelete,
    statusSearchValue,
    setStatusSearchValue,
    typeSearchValue,
    setTypeSearchValue,
    setFilterVal,
    filterVal,
    setSearchVal,
    setPage,
}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="container-worker d-flex flex-row">
                <div className="worker-content d-flex flex-column">
                    <div className="filterSection flex items-center mb-3">
                        <div>見積・相談依頼</div>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '250px',
                                display: 'flex',
                                marginLeft: '85px',
                                '.input-search': {
                                    border: '1px solid #cdd1d5',
                                    borderTopLeftRadius: '2px',
                                    borderBottomLeftRadius: '2px',
                                    marginBottom: '14.5px',
                                    paddingLeft: '14px',
                                },
                                '.MuiTextField-root': {
                                    border: '1px solid #cdd1d5',
                                    width: '100%',
                                    borderTopLeftRadius: '2px',
                                    borderBottomLeftRadius: '2px',
                                    paddingLeft: '14px',
                                    // marginRight: '12.5px',
                                },
                                '.MuiInputBase-input': {
                                    height: '35px',
                                    padding: '0px !important',
                                },
                                '.MuiButtonBase-root': {
                                    minWidth: '0px',
                                },
                            }}
                        >
                            <TextField
                                autoComplete="off"
                                placeholder="検索"
                                type="text"
                                name="free_word"
                                value={filterVal}
                                onChange={(evt) => setFilterVal(evt.target.value)}
                            />
                            <Button onClick={() => {
                                setSearchVal(filterVal)
                                setPage(1)
                            }}
                                sx={{
                                    borderTop: '1px solid #cdd1d5',
                                    borderBottom: '1px solid #cdd1d5',
                                    borderRight: '1px solid #cdd1d5',
                                    borderRadius: 0,
                                    borderTopRightRadius: '2px',
                                    borderBottomRightRadius: '2px',
                                    width: '40px',
                                }}
                            >
                                <img
                                    src={SearchGlass}
                                    alt="search icon"
                                    style={{ width: '20px' }}
                                />
                            </Button>
                        </Box>

                        <Box
                            className="w-[145px] ml-4"
                            sx={{
                                '.select-search__input': {
                                    border: '1px solid #D9D9D9',
                                    boxShadow: 'none',
                                },
                                '.select-search__options': {
                                    paddingLeft: 0,
                                },
                                '.select-search__option.is-selected': {
                                    background: '#215493',
                                },
                            }}
                        >
                            <SelectSearch
                                options={typeOptions}
                                search
                                emptyMessage={'該当なし'}
                                placeholder="タイプ"
                                value={typeSearchValue}
                                onChange={(value) => {
                                    setPage(1);
                                    setTypeSearchValue((prev) => {
                                        if (prev === value) {
                                            return 'all';
                                        }
                                        return value;
                                    });
                                }}
                            />
                        </Box>

                        <Box
                            className="w-[140px] ml-2"
                            sx={{
                                '.select-search__input': {
                                    border: '1px solid #D9D9D9',
                                    boxShadow: 'none',
                                },
                                '.select-search__options': {
                                    paddingLeft: 0,
                                },
                                '.select-search__option.is-selected': {
                                    background: '#215493',
                                },
                            }}
                        >
                            <SelectSearch
                                options={statusOptions}
                                search
                                emptyMessage={'該当なし'}
                                placeholder="ステータス"
                                value={statusSearchValue}
                                onChange={(value) => {
                                    setPage(1);
                                    setStatusSearchValue((prev) => {
                                        if (prev === value) {
                                            return 'all';
                                        }
                                        return value;
                                    });
                                }}
                            />
                        </Box>
                    </div>
                    <div className="table-workers d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            <TableContainer table-layout={'auto'}>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '20%' }}
                                            >
                                                <div>Lineアカウント</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '10%' }}
                                            >
                                                <div>タイプ</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '15%' }}
                                            >
                                                <div>ステータス</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '10%' }}
                                            >
                                                <div>作成日</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '10%' }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {quoteList.length > 0 && quoteList.map((row, ind) => {
                                            return (
                                                <StyledTableRow key={ind}>
                                                    <StyledTableCell
                                                        className="!text-left"
                                                        component="th"
                                                        scope="row"
                                                        style={{ width: '20%' }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    row.avatar_url ||
                                                                    defaultAvatar
                                                                }
                                                                alt={`${row.user_name} profile photo`}
                                                                className="rounded-[50%] w-[40px] h-[40px] mr-[5px]"
                                                            />
                                                            <span>
                                                                {row.user_name}
                                                            </span>
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        className="!text-left"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {
                                                                QuoteTypeEnum[
                                                                    row
                                                                        .quote_type
                                                                ]?.title
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className="!text-left"
                                                        style={{ width: '15%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                                color: QuoteStatusEnum[
                                                                    row.status
                                                                ]?.textColor,
                                                                backgroundColor:
                                                                    QuoteStatusEnum[
                                                                        row
                                                                            .status
                                                                    ]?.bgColor,
                                                                borderRadius:
                                                                    '15px',
                                                                padding:
                                                                    '4px 9px',
                                                            }}
                                                        >
                                                            {
                                                                QuoteStatusEnum[
                                                                    row.status
                                                                ]?.title
                                                            }
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row?.created_at && moment(row?.created_at).format('YYYY年MM月DD日')}
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div className="flex justify-start items-start">
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    width: 24,
                                                                    height: 24,
                                                                    margin: '0px 8px 0px 4px',
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/quote/list?id=${row.id}&editable=true`,
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        PencilIcon
                                                                    }
                                                                    alt="pencil icon"
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    width: 24,
                                                                    height: 24,
                                                                    margin: '0px 4px',
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/quote/list/invoice/${row.id}?invoice_id=${row?.invoice && row?.invoice.id}`,
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        InvoiceIcon
                                                                    }
                                                                    alt="invoice icon"
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    width: 24,
                                                                    height: 24,
                                                                    margin: '0px 4px',
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/quote/list?id=${row.id}`,
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        EyeIcon
                                                                    }
                                                                    alt="eye icon"
                                                                />
                                                            </Box>
                                                            {!row?.invoice && <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    width: 24,
                                                                    height: 24,
                                                                    margin: '0px 4px',
                                                                }}
                                                                onClick={() => {
                                                                    openDelete();
                                                                    setQuoteId(row?.id)
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        RedTrash
                                                                    }
                                                                    alt="red trash icon"
                                                                />
                                                            </Box>}
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                {quoteList.length === 0 && <div className='text-center p-[10px] w-full' >データがありません。</div>}
                            </TableContainer>
                        </div>
                    </div>
                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]': statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                </div>
                <QuoteController />
            </div>
            <ModalDelete>
                <div
                    className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                    style={{ width: '449px', height: '285px' }}
                >
                    <div className="flex justify-end">
                        <CloseOutlined onClick={closeDelete} />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                        <img
                            src={ExclamationIcon}
                            className="w-[80px] h-[80px]"
                            alt="exclamation icon"
                        />
                        <div className="text-[#215493] font-medium text-[16px] pt-[5px]">
                            警告
                        </div>
                        <div className="text-[#3A3B3C] font-normal text-[13px] pt-[5px]">
                            このアカウントを本当に削除しますか?
                        </div>
                        <div
                            className="flex gap-[10px]"
                            style={{ marginTop: '40px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                                onClick={closeDelete}
                            >
                                キャンセル
                            </button>
                            <button onClick={deleteQuote}
                                className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]">
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            </ModalDelete>
        </>
    );
};

const QuoteList: FC<ReceivedProps> = (props) => {
    return <QuoteListLayout {...useQuoteList(props)} />;
};

export default QuoteList;
